/* eslint-disable indent */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import idx from 'idx'
import { PrismicRichText } from '@prismicio/react'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'
import greenCheckMarkIcon from '../../../static/icons/check-icons/green-stroke-check-icon.svg'
import plusIcon from '../../../static/icons/misc-icons/plus.svg'
import { theme } from '../../styles'
import { useUserLang } from '../../context/UserLangContext'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '8px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '8px')};

  ul,
  ol {
    list-style-position: outside;
    margin-top: 1.75rem;
    margin-left: 32px;

    @media (max-width: 800px) {
      margin-left: 24px;
    }
  }
  @media (max-width: 1050px) {
    padding-top: ${p => (p.hasTopPadding ? '64px' : '8px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '8px')};
    h2,
    h3 {
      margin: 0 auto;
    }
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? p.theme.color[p.backgroundColor] : 'transparent')};
  margin-left: -100%;
  margin-top: ${p => (p.hasTopPadding ? '-80px' : '-8px')};

  @media (max-width: 1050px) {
    margin-top: ${p => (p.hasTopPadding ? '-64px' : '-8px')};
  }
`

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: ${p => (p.centerColTextLeftAligned ? 'flex-start' : 'center')};
  max-width: 750px;

  @media (max-width: 1050px) {
    * {
      text-align: ${p => (p.centerColTextLeftAligned ? 'left!important' : 'inherit')};
    }
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  ${p =>
    p.headingOnly &&
    `
    margin-bottom: 0px;
  `}

  @media (max-width: 1050px) {
    justify-content: center;
  }
`

const ColWrapper = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.cardStyles &&
    `
    box-shadow: 0px 0px 14px 3px ${theme.color.darkShadow};
    background-color: ${theme.color.white};
    padding: 40px 58px;
    border-radius: 16px;
  `}

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    * {
      text-align: center !important;
    }
  }
`

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${p =>
    !p.single &&
    css`
      &:first-of-type {
        padding-right: 120px;
      }
    `}
  @media (max-width: 1050px) {
    &:first-of-type {
      padding-right: 0px;
    }
  }
`

const ColText = styled(Text)``

const CheckMarkListWrapper = styled(Text)`
  li {
    color: ${p => (p.darkmode ? theme.color.white : theme.color.offBlack)};
    list-style-type: none;
    background-image: url(${p => (p.plusIcons ? plusIcon : p.darkmode ? greenCheckMarkIcon : violetCheckMarkIcon)});
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: top ${p => (p.plusIcons ? '5px' : '8px')} left;
  }

  @media (max-width: 1050px) {
    ul {
      width: fit-content;
      margin: 0 auto;
      text-align: left !important;
    }
    li {
      text-align: left !important;
    }
  }
`

const ColButtonWrapper = styled.div`
  display: flex;
  padding-top: 16px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  @media (max-width: 1050px) {
    padding-top: 16px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ComplexCenteredHeading = styled(Text)`
  span {
    font-size: ${theme.fonts.size.heading2};
    font-family: ${theme.fonts.family.heading2};
    font-weight: ${theme.fonts.weight.heading2};
    line-height: ${theme.fonts.lineHeight.heading2};
    letter-spacing: ${theme.fonts.letterSpacing.heading2};
  }
`

const WrapText = styled(Text)`
  ${props =>
    props.lang !== 'en' &&
    `
  word-break: break-word;
  hyphens: auto;
`}
`

const FlexibleText = ({ slice: { primary } }) => {
  const largeCapsText = primary.large_caps_text?.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const centeredHeading = primary.centered_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.centered_heading.richText} />
  )
  const coloredText = primary.colored_text?.richText?.[0]?.text && primary.colored_text.text
  const secondaryCenteredHeading =
    primary.secondary_centered_heading?.richText?.[0]?.text && primary.secondary_centered_heading.text
  const centerColText = primary.center_col_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.center_col_text.richText} />
  )
  const centerColCheckMarkList = primary.center_col_checkmark_list.richText?.[0]?.text && (
    <PrismicRichText field={primary.center_col_checkmark_list.richText} />
  )
  const leftLargeCapsText = primary.left_large_caps_text?.richText?.[0]?.text && (
    <PrismicRichText field={primary.left_large_caps_text.richText} />
  )
  const leftHeading = primary.left_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.left_heading.richText} />
  )
  const rightLargeCapsText = primary.right_large_caps_text?.richText?.[0]?.text && (
    <PrismicRichText field={primary.right_large_caps_text.richText} />
  )
  const rightHeading = primary.right_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.right_heading.richText} />
  )
  const leftColText = primary.left_col_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.left_col_text.richText} />
  )
  const rightColText = primary.right_col_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.right_col_text.richText} />
  )
  const leftColCheckMarkList = primary.left_col_checkmark_list.richText?.[0]?.text && (
    <PrismicRichText field={primary.left_col_checkmark_list.richText} />
  )
  const rightColCheckMarkList = primary.right_col_checkmark_list.richText?.[0]?.text && (
    <PrismicRichText field={primary.right_col_checkmark_list.richText} />
  )
  const rightColButtonText = primary.right_col_button_text.richText?.[0]?.text
  const leftColButtonText = primary.left_col_button_text.richText?.[0]?.text
  const leftColButtonUrl = idx(primary, _ => _.left_col_button_link.url)
  const rightColButtonUrl = idx(primary, _ => _.right_col_button_link.url)
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const backgroundColor = primary.background_color
  const anchorId = primary.anchor_id
  const darkmode = primary.background_color === 'purple900'
  const centerColTextLeftAligned = primary?.center_col_text_left_aligned
  const hasTopPadding = primary.has_top_padding === null ? true : primary.has_top_padding
  const hasBottomPadding = primary.has_bottom_padding === null ? true : primary.has_bottom_padding
  const cardStyles = primary.card_styles
  const headingOnly =
    centeredHeading &&
    !leftHeading &&
    !rightHeading &&
    !centerColText &&
    !centerColCheckMarkList &&
    !leftColText &&
    !rightColText &&
    !leftColCheckMarkList &&
    !rightColCheckMarkList &&
    !rightColButtonText &&
    !leftColButtonText
  const { userLang } = useUserLang()

  return (
    <Wrapper id={anchorId || null} hasTopPadding={hasTopPadding} hasBottomPadding={hasBottomPadding}>
      {(centeredHeading || centerColText || centerColCheckMarkList || largeCapsText) && (
        <CenteredWrapper centerColTextLeftAligned={centerColTextLeftAligned}>
          {largeCapsText && (
            <Text font="largeCaps" richText color={backgroundColor === 'purple900' ? 'yellow200' : 'purple500'}>
              {largeCapsText}
            </Text>
          )}
          {centeredHeading && (
            <HeaderWrapper headingOnly={headingOnly}>
              {coloredText ? (
                <ComplexCenteredHeading
                  color={darkmode ? 'white' : 'offBlack'}
                  heading2
                  center={!centerColTextLeftAligned}
                >
                  {primary.centered_heading.text}
                  <Text isSpan className="colored-text" color={darkmode ? 'green300' : 'purple500'}>
                    {' '}
                    {coloredText}
                  </Text>
                  {secondaryCenteredHeading && (
                    <Text isSpan className="secondary-heading" color={darkmode ? 'white' : 'offBlack'}>
                      {' '}
                      {secondaryCenteredHeading}
                    </Text>
                  )}
                </ComplexCenteredHeading>
              ) : (
                <WrapText
                  color={darkmode ? 'white' : 'offBlack'}
                  center={!centerColTextLeftAligned}
                  richText
                  lang={userLang}
                >
                  {centeredHeading}
                </WrapText>
              )}
            </HeaderWrapper>
          )}
          {centerColText && (
            <Col single>
              <ColText color={darkmode ? 'white' : 'offBlack'} center={!centerColTextLeftAligned} richText>
                {centerColText}
              </ColText>
            </Col>
          )}
          {centerColCheckMarkList && (
            <Col single>
              <CheckMarkListWrapper darkmode={darkmode} plusIcons={primary.plus_icons} richText>
                {centerColCheckMarkList}
              </CheckMarkListWrapper>
            </Col>
          )}
        </CenteredWrapper>
      )}
      {(leftLargeCapsText ||
        leftHeading ||
        leftColText ||
        rightLargeCapsText ||
        rightHeading ||
        rightColText ||
        leftColCheckMarkList ||
        rightColCheckMarkList ||
        leftColButtonText ||
        rightColButtonText) && (
        <ColWrapper cardStyles={cardStyles}>
          <Col>
            {leftLargeCapsText && (
              <Text font="largeCaps" richText color={backgroundColor === 'purple900' ? 'yellow200' : 'purple500'}>
                {leftLargeCapsText}
              </Text>
            )}
            {leftHeading && (
              <HeaderWrapper headingOnly={headingOnly}>
                <Text color={darkmode ? 'white' : 'offBlack'} richText>
                  {leftHeading}
                </Text>
              </HeaderWrapper>
            )}
            {leftColText && (
              <ColText color={darkmode ? 'white' : 'offBlack'} richText>
                {leftColText}
              </ColText>
            )}
            {leftColCheckMarkList && (
              <CheckMarkListWrapper darkmode={darkmode} plusIcons={primary.plus_icons} richText>
                {leftColCheckMarkList}
              </CheckMarkListWrapper>
            )}
            {leftColButtonText && leftColButtonUrl && (
              <ColButtonWrapper>
                <Button
                  buttonType={darkmode ? 'tertiary reversed' : 'primary'}
                  to={leftColButtonUrl}
                  text={leftColButtonText}
                />
              </ColButtonWrapper>
            )}
          </Col>
          <Space height={0} tabletHeight={16} />

          <Col>
            {rightLargeCapsText && (
              <Text font="largeCaps" richText color={backgroundColor === 'purple900' ? 'yellow200' : 'purple500'}>
                {rightLargeCapsText}
              </Text>
            )}
            {rightHeading && (
              <HeaderWrapper headingOnly={headingOnly}>
                <Text color={darkmode ? 'white' : 'offBlack'} richText>
                  {rightHeading}
                </Text>
              </HeaderWrapper>
            )}
            {rightColText && (
              <ColText color={darkmode ? 'white' : 'offBlack'} richText>
                {rightColText}
              </ColText>
            )}
            {rightColCheckMarkList && (
              <CheckMarkListWrapper darkmode={darkmode} plusIcons={primary.plus_icons} richText>
                {rightColCheckMarkList}
              </CheckMarkListWrapper>
            )}
            {rightColButtonText && rightColButtonUrl && (
              <ColButtonWrapper>
                <Button
                  buttonType={darkmode ? 'tertiary reversed' : 'primary'}
                  to={rightColButtonUrl}
                  text={rightColButtonText}
                />
              </ColButtonWrapper>
            )}
          </Col>
        </ColWrapper>
      )}

      {buttonText && buttonUrl && (
        <ButtonWrapper>
          <Button buttonType={darkmode ? 'tertiary reversed' : 'primary'} to={buttonUrl} text={buttonText} />
        </ButtonWrapper>
      )}
      <BG
        className="bg"
        backgroundColor={backgroundColor}
        hasTopPadding={hasTopPadding}
        hasBottomPadding={hasBottomPadding}
      />
    </Wrapper>
  )
}

export default FlexibleText

export const query = graphql`
  fragment FlexibleText on PrismicPageDataBodyFlexibleText {
    ...SliceBase
    primary {
      background_color
      anchor_id
      has_top_padding
      has_bottom_padding
      button_text {
        ...TextContent
      }
      center_col_text {
        ...TextContent
      }
      center_col_text_left_aligned
      button_link {
        ...LinkContent
      }
      large_caps_text {
        ...TextContent
      }
      centered_heading {
        ...TextContent
      }
      colored_text {
        ...TextContent
      }
      secondary_centered_heading {
        ...TextContent
      }
      center_col_checkmark_list {
        ...TextContent
      }
      left_col_text {
        ...TextContent
      }
      left_col_checkmark_list {
        ...TextContent
      }
      left_col_button_text {
        ...TextContent
      }
      left_col_button_link {
        ...LinkContent
      }
      left_large_caps_text {
        ...TextContent
      }
      left_heading {
        ...TextContent
      }
      right_col_text {
        ...TextContent
      }
      right_col_checkmark_list {
        ...TextContent
      }
      right_large_caps_text {
        ...TextContent
      }
      right_heading {
        ...TextContent
      }
      right_col_button_text {
        ...TextContent
      }
      right_col_button_link {
        ...LinkContent
      }
      card_styles
      plus_icons
    }
  }
`
